<template>
	<div style="">
		<h1>Dashboard</h1>
	</div>
</template>
<script>
export default {
  name:'AdminDashboard',
  created () {
    this.$store.commit('SET_LAYOUT', 'admin')
  }
}
</script>